<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.financeLog')" />

    <v-card-text>
      <v-form v-model="valid" ref="form">
        <v-container fluid>
          <v-row>
            <v-col class="pb-0 pt-0" cols="12">
              <v-radio-group
                class="mt-0"
                v-model="categoryType"
                row
                label="Category"
              >
                <v-radio label="Bank Statement" value="1" />
                <v-radio label="Cash-In | Cash-Out" value="2" />
                <v-radio label="Deposite | Withdraw" value="3" />
              </v-radio-group>
            </v-col>

            <v-col v-if="categoryType == '2'" class="pb-0 pt-0" cols="12">
              <v-radio-group
                class="mt-0"
                v-model="subCategoryType"
                row
                label="Sub Category"
              >
                <v-radio label="Income" value="1" />
                <v-radio label="Expense" value="2" />
              </v-radio-group>
            </v-col>

            <v-col v-if="categoryType == '3'" class="pb-0 pt-0" cols="12">
              <v-radio-group
                class="mt-0"
                v-model="subCategoryType"
                row
                label="Sub Category"
              >
                <v-radio label="Deposite" value="1" />
                <v-radio label="Withdraw" value="2" />
              </v-radio-group>
            </v-col>

            <v-col class="pb-0" cols="12" lg="6" md="6" sm="6">
              <v-text-field
                label="USD"
                outlined
                type="number"
                v-model="obj.amount"
                :rules="generalRule"
              />
            </v-col>

            <v-col class="pb-0" cols="12" lg="6" md="6" sm="6">
              <v-text-field
                label="KHR"
                outlined
                type="number"
                v-model="obj.kh"
                :rules="generalRule"
              />
            </v-col>

            <v-col v-if="categoryType != '2'" class="pb-0" cols="12">
              <v-select
                v-model="select"
                :items="bankAccountList"
                :item-text="(item) => item.account + ' - ' + item.no"
                return-object
                label="Select Bank Account"
                outlined
                :rules="generalRule"
              >
              </v-select>
            </v-col>

            <v-col class="pb-0" cols="12">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="obj.date"
                    label="Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :rules="generalRule"
                  />
                </template>
                <v-date-picker
                  v-model="obj.date"
                  @input="handleDate"
                  no-title
                />
              </v-menu>
            </v-col>

            <v-col class="pb-0" cols="12">
              <v-textarea outlined label="Remark" v-model="obj.note" />
            </v-col>

            <v-col class="pb-0" cols="12">
              <v-file-input
                label="Attchment"
                outlined
                show-size
                counter
                multiple
                prepend-icon=""
                prepend-inner-icon="mdi-file"
                v-model="files"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 2"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>

                  <span
                    v-else-if="index === 2"
                    class="overline grey--text text--darken-3 mx-2"
                  >
                    +{{ files.length - 2 }} File(s)
                  </span>
                </template>
              </v-file-input>

              <span class="display-1 font-weight-bold">
                List of Documents
              </span>

              <v-sheet
                width="100%"
                elevation="4"
                class="py-4"
                :height="obj.attach.length < 1 ? '200px' : ''"
              >
                <span
                  class="d-flex align-center justify-center"
                  v-for="(item, key) in obj.attach"
                  :key="key"
                >
                  <v-list-item
                    class="tile ml-4 mr-2 my-2"
                    :href="dataUrl + item.md5"
                    target="_blank"
                  >
                    {{ item.filename }}
                  </v-list-item>

                  <v-btn
                    color="red"
                    class="ml-2 mr-4"
                    @click="removeAttach(item)"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </span>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8 pt-0">
      <v-spacer />
      <v-btn @click="handleCancel" color="red" outlined>
        {{ this.$vuetify.lang.t("$vuetify.abolish") }}
      </v-btn>
      <v-btn @click="handleSubmit" color="primary">
        {{ this.$vuetify.lang.t("$vuetify.sub") }}
      </v-btn>
      <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
import serverConfig from "@/utils/serverConfig";

export default {
  name: "FinancialCRUD",
  props: { dialog: Boolean, bankAccountList: Array, obj: Object },
  data() {
    return {
      valid: false,
      generalRule: [(v) => !!v || "this field is required"],
      menu: false,
      dataUrl: serverConfig.img_url,
      files: undefined,
    };
  },
  methods: {
    handleCancel() {
      this.resetValidation();
      this.$emit("update:dialog", false);
      this.files = undefined;
    },
    handleSubmit() {
      if (this.$refs.form.validate()) {
        this.$emit("editItem", this.obj, this.files);
        this.files = undefined;
        this.resetValidation();
      } else {
        console.log("false");
      }
    },
    handleDate(value) {
      this.menu = false;
      this.obj.date = value;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    removeAttach(item) {
      this.obj.attach.splice(this.obj.attach.indexOf(item), 1);
    },
  },
  computed: {
    select: {
      get: function () {
        return this.bankAccountList.filter(
          (p) => p.no == this.obj.bank_account
        )[0];
      },
      set: function (obj) {
        this.obj.bank_account = obj.no;
        this.obj.bank_name = obj.name;
      },
    },
    categoryType: {
      get: function () {
        return this.obj.type.toString();
      },
      set: function (value) {
        this.obj.type = value;
      },
    },
    subCategoryType: {
      get: function () {
        return this.obj.in_out.toString();
      },
      set: function (value) {
        this.obj.in_out = value;
      },
    },
  },
};
</script>

<style scoped>
.tile {
  border: 0.5px solid gray;
}
.tile:hover {
  background: rgb(220, 220, 220, 0.2);
}
.tile:active {
  background: rgb(211, 211, 211, 0.2);
}
</style>
